import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.buildNumber) {
  console.log(`Build number: ${environment.buildNumber}`);
}

// GIFLENS-https://media2.giphy.com/media/h26fLv6Sba1lMegpf1/200.gif
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

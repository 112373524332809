import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';

import { ErrorReasons } from '../../error/error-reasons.enum';
import { AppModeTypes } from '../helpers/app-mode.enum';
import { Lookup } from '../helpers/lookup';
import { CommunityService } from './community.service';
import { NavigationService } from './navigation.service';
import { OfflineService } from './offline.service';
import { SessionStorageService } from './session-storage.service';
import { ThemeService } from './theme.service';

import { ICommunity } from '@ml/common';

@Injectable({
  providedIn: 'root'
})
export class CommunityResolverService implements Resolve<ICommunity> {
  constructor(
    private navigator: NavigationService,
    private communityService: CommunityService,
    private offlineService: OfflineService,
    private lookup: Lookup,
    private sessionStorageService: SessionStorageService,
    private theme: ThemeService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ICommunity> {
    let communityId = +route.paramMap.get('communityId');

    if (!communityId || isNaN(communityId)) {
      // If opening from Desktop, grab community id from storage
      communityId = this.offlineService.getFirstDownloadedCommunityId();
    }

    if (!communityId) {
      return this.navigator.handleErrorRedirect(ErrorReasons.CommunityNotFound);
    }

    if (communityId)
      this.sessionStorageService.setItem(
        this.lookup.CurrentCommunityIdSessionKey,
        communityId.toString()
      );

    this.navigator.detectApplicationMode(null, window.location.pathname);

    return this.communityService.get(communityId, this.navigator.AppMode).pipe(
      take(1),
      mergeMap((community: ICommunity) => {
        if (community) {
          if (this.doesNotHaveAccessForActiveProduct(community, route))
            return this.navigator.handleErrorRedirect(ErrorReasons.ProductNotEnabledForCommunity);

          this.theme.initializeFromSettings();
          return of(community);
        } else {
          return this.navigator.handleErrorRedirect(ErrorReasons.CommunityNotFound);
        }
      }),
      catchError(() => this.navigator.handleErrorRedirect(ErrorReasons.CommunityNotFound))
    );
  }

  private doesNotHaveAccessForActiveProduct(community: ICommunity, route: ActivatedRouteSnapshot) {
    return (
      ((this.navigator.AppMode !== AppModeTypes.HomeConfigurator &&
        !community.ProductPermissions.HasMyScpEnabled) ||
        (this.navigator.AppMode === AppModeTypes.HomeConfigurator &&
          !community.ProductPermissions.HasHomeConfiguratorEnabled)) &&
      route.queryParams['ml-debug'] !== 'true'
    );
  }
}

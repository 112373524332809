import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ErrorReasons } from './error/error-reasons.enum';
import { ErrorComponent } from './error/error.component';
import {
  HomeConfiguratorRootComponent
} from './home-configurator/home-configurator-root.component';
import { IconsComponent } from './icons/icons.component';
import { MyScpRootComponent } from './myscp/myscp-root.component';

import { locationHrefContainsStandalone } from './utility/helpers/helpers';
import { CommunityResolverService } from './utility/services/community-resolver.service';

const routes: Routes = [
  {
    path: 'error',
    component: ErrorComponent
  },

  // helpful to list out all icons, not actual app page
  {
    path: 'icons',
    component: IconsComponent
  },
  {
    path: 'scp/:communityId',
    component: MyScpRootComponent,
    resolve: {
      community: CommunityResolverService
    },
    loadChildren: () => import('./myscp/myscp.module').then(m => m.MyScpModule)
  },
  {
    path: 'home-configurator/:communityId',
    component: HomeConfiguratorRootComponent,
    resolve: {
      community: CommunityResolverService
    },
    loadChildren: () =>
      import('./home-configurator/home-configurator.module').then(m => m.HomeConfiguratorModule)
  },
  {
    path: '**',
    redirectTo: `/error?reason=${ErrorReasons.RouteNotFound}`
  }
];

const determinePreloadStrategy = () =>
  locationHrefContainsStandalone() ? NoPreloading : PreloadAllModules;

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { preloadingStrategy: determinePreloadStrategy() }
      // { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { A11yModule } from '@angular/cdk/a11y';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { IconsComponent } from './icons/icons.component';
import { SharedAppModule } from './shared/shared-app.module';
import { AuthInterceptor } from './utility/services/auth.interceptor';
import {
  HomeBuyerIntegrationServiceProvider
} from './utility/services/home-buyer/home-buyer-integration-factory.service';
import { ServiceWorkerUpdate } from './utility/services/service-worker-update.service';

const shouldEnableServiceWorker = () =>
  environment.useServiceWorker &&
  window.location.href.includes('/scp/') &&
  !window.location.href.includes('?standalone');

@NgModule({ declarations: [AppComponent, ErrorComponent, IconsComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedAppModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: shouldEnableServiceWorker()
        }),
        A11yModule,
        MatDialogModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        ServiceWorkerUpdate,
        HomeBuyerIntegrationServiceProvider,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}

import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { environment } from '../environments/environment';
import { ServiceWorkerUpdate } from './utility/services/service-worker-update.service';

@Component({
  selector: 'app-root',
  template: `
    <div *ngIf="loading" class="spin-stretch-container">
      <div class="spin-stretch"></div>
    </div>
    <router-outlet></router-outlet>
  `
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(private router: Router, private serviceWorkerUpdate: ServiceWorkerUpdate) {}

  ngOnInit() {
    this.serviceWorkerUpdate.initialize();

    if (environment.useUseBerry) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://api.useberry.com/integrations/liveUrl/scripts/useberryScript.js';
      document.body.appendChild(script);
    }

    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
